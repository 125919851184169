<style>
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    .not-draggable {
        cursor: no-drop;
    }

    .list-block{
        background: #F5F5F5;
        /* width: 500px; */
        padding: 20px;
        cursor: pointer;
        margin-bottom: 1px;
        display: flex;
    }

    .red{
        background: #ffb7b7;

    }

    .list-block .icon{
        width: 10%;
    }

    .list-block .label{
        width: 50%;
    }

    .list-block .label-info{
        width: 80%;
    }

    .list-block .required{
        width: 30%;
        text-align:right;
        padding: 0 10px;
    }

    .list-block .display{
        width: 10%;
        text-align:right;
        padding: 0 10px;
    }

    .list-group{
        /* width: 500px; */
    }
</style>

<template>
    <div>
        <b-row>
            <b-col cols="6">
                <h3>Information</h3>
                <div style="clear: both" class="mb-3"></div>
                <draggable
                    v-model="informationList"
                    :disabled="!enabled"
                    item-key="name"
                    class="list-group"
                    ghost-class="ghost"
                    @start="dragging = true"
                    @end="dragging = false"
                >
                    <div v-for="element in informationList" :key="element.id">
                        <div class="list-block" :style="!element.show?'background: #ffb7b7;':''">
                            <div class="icon">
                                <feather-icon
                                    icon="MenuIcon"
                                    class="mr-50"
                                />
                            </div>
                            <div class="label-info">{{element.name}}</div>
                            <div class="display">
                                <div v-if="!element.show" v-on:click="changeDisplayInformation(element.settingID)" >
                                    <feather-icon
                                        icon="EyeOffIcon"
                                        class="mr-50"
                                    />
                                </div>
                                <div v-if="element.show" v-on:click="changeDisplayInformation(element.settingID)" >
                                    <feather-icon
                                        icon="EyeIcon"
                                        class="mr-50"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </b-col>
            <b-col cols="6">
                <h3>Registration Form</h3>
                <div style="clear: both" class="mb-3"></div>
                <div v-if="isLoading && !fetchComplete" class="text-center">
                    <div style="clear: both" class="mb-1"></div>
                    <b-spinner>Loading...</b-spinner>
                </div>
                <div v-if="!isLoading && fetchComplete">
                    <draggable
                        v-model="registrationForm"
                        :disabled="!enabled"
                        item-key="name"
                        class="list-group"
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="dragging = false"
                    >
                        <div v-for="element in registrationForm" :key="element.id">
                            <div class="list-block" :style="!element.show?'background: #ffb7b7;':''">
                                <div class="icon">
                                    <feather-icon
                                        icon="MenuIcon"
                                        class="mr-50"
                                    />
                                </div>
                                <div class="label-info">{{element.name}}</div>
                                <div class="required">
                                    <div v-if="!element.required && !element.mandatory && element.showRequired" v-on:click="changeRequired(element.settingID)" >
                                        <feather-icon
                                            icon="SquareIcon"
                                            class="mr-50"
                                        />
                                        Required
                                    </div>
                                    <div v-if="element.required && !element.mandatory && element.showRequired" v-on:click="changeRequired(element.settingID)" >
                                        <feather-icon
                                            icon="CheckSquareIcon"
                                            class="mr-50"
                                        />
                                        Required
                                    </div>
                                    <div v-if="!element.showRequired">
                                        &nbsp;
                                    </div>
                                </div>
                                <div class="display">
                                    <div v-if="!element.show && !element.mandatory" v-on:click="changeDisplay(element.settingID)" >
                                        <feather-icon
                                            icon="EyeOffIcon"
                                            class="mr-50"
                                        />
                                    </div>
                                    <div v-if="element.show && !element.mandatory" v-on:click="changeDisplay(element.settingID)" >
                                        <feather-icon
                                            icon="EyeIcon"
                                            class="mr-50"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </b-col>
        </b-row>
        <br>
        <b-row>
            <b-button size="md" style="margin: 20px auto;" variant="primary" @click="save()" :disabled="isLoadingSave">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span>Save</span>
            </b-button>
        </b-row>
        
    </div>
</template>

<script>

import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BInputGroup, BInputGroupAppend, BAlert, BModal, BSpinner, BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'

export default {
  components: {
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BInputGroup,
        BInputGroupAppend,
        BAlert,
        BModal,
        BSpinner,
        BOverlay,
        draggable
    },
    props: {
        eventId: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            isLoading: false,
            isLoadingSave: false,
            fetchComplete: false,
            enabled: true,
            informationList: [
                {
                    name: "Image Banner",
                    type: "image",
                    url: "http://",
                    content: null,
                    show: true,
                    settingID: 1
                },
                {
                    name: "Title",
                    type: "title",
                    url: null,
                    content: "",
                    show: true,
                    settingID: 2
                },
                {
                    name: "Information",
                    type: "info",
                    url: null,
                    content: "",
                    show: true,
                    settingID: 3
                },
                {
                    name: "Event Date & Time",
                    type: "datetime",
                    url: null,
                    content: "",
                    show: true,
                    settingID: 4
                },
                {
                    name: "Location",
                    type: "location",
                    url: null,
                    content: "",
                    show: true,
                    settingID: 5
                },
                {
                    name: "Price",
                    type: "price",
                    url: null,
                    content: "",
                    show: false,
                    settingID: 8
                },
                {
                    name: "Payment Info",
                    type: "payment",
                    url: null,
                    content: "",
                    show: false,
                    settingID: 9
                },
                {
                    name: "Event Category",
                    type: "category",
                    url: null,
                    content: "",
                    show: false,
                    settingID: 6
                },
                {
                    name: "Event Format",
                    type: "format",
                    url: null,
                    content: "",
                    show: false,
                    settingID: 7
                }
            ],
            registrationForm: [],
            dragging: false
        }
    },
    methods: {
        changeRequired(id){
            this.registrationForm.forEach(element => {
                if(element.settingID == id){
                    element.required = !element.required
                }
            });
        },
        changeDisplay(id){
            this.registrationForm.forEach(element => {
                if(element.settingID == id){
                    element.show = !element.show
                }
            });
        },
        changeDisplayInformation(id){
            this.informationList.forEach(element => {
                if(element.settingID == id){
                    element.show = !element.show
                }
            });
        },
        save(){
            this.isLoadingSave = true
            const requestData = {
                event_id: this.eventId,
                information: this.informationList,
                registration_form: this.registrationForm
            }

            this.$http.post('/admin/v1/registration-form/' + this.eventId, requestData)
            .then(res => {         
                const data = res.data
                const message = data.message

                if(!data.status) {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                    title: `Error`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: message,
                    },
                })
                }
                else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                    title: `Success`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: message,
                    },
                })

                }

                this.isLoadingSave = false
            })
        },
        fetchData() {
            this.isLoading = true
            this.$http.get('/admin/v1/registration-form/'+this.eventId)
                .then(res => {
                const data = res.data
                const message = data.message

                if(!data.status) {
                    this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Error`,
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text: message,
                    },
                    })
                }
                else {
                    const currentData = res.data.data

                    this.registrationForm = currentData.registration_form
                    if(currentData.information != null) {
                        this.informationList = currentData.information

                    }
                    
                }
                this.fetchComplete = true
                this.isLoading = false
            })
        },
    },
    created() {
        this.fetchData()
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

